<template>
  <div class="editReport">
    <!--编辑报告页面-->
    <div class="title-box">
      <div class="title">
        {{ title }}
      </div>

      <div class="btn-box">
        <el-button type="text" class="btn-text" @click="linkTo">取消</el-button>
        <el-button type="primary" class="cli-btn" @click="subFrom"
          >下一步<i class="el-icon-arrow-right el-icon--right"></i>
        </el-button>
      </div>
    </div>
    <div class="content-box">
      <p style="font-size: 20px">
        {{ allTitle }}

        {{ $route.query.type | setTitle(menuList) }}
      </p>
      <div class="cnt-title">
        <el-input
          v-model="title"
          class="el-title"
          placeholder="标题"
        ></el-input>
        <el-input
          v-model="intro"
          class="el-title"
          type="textarea"
          placeholder="简介"
          maxlength="30"
          show-word-limit
        ></el-input>
      </div>
      <!--第一组-->
      <div class="item-title">
        <div class="left-box">
          <!--<div class="num-list">1</div>-->
          <el-button
            class="suc-btn"
            type="success"
            icon="el-icon-check cle-el"
            circle
          ></el-button>
        </div>
        <div class="title">设置错题定义</div>
      </div>
      <div class="item-cnt">
        <div class="item-left-box"></div>
        <div class="item-right-box">
          <div
            v-if="$route.query.type == 1 || $route.query.type == 2"
            class="list"
          >
            <div class="list-title">统计</div>
            <div class="list-cnt">
              前
              <input
                v-model="from.scoreRateBeforeValue"
                type="text"
                class="df-text"
              />
              %的平均分/得分率，后
              <input
                v-model="from.scoreRateEndValue"
                type="text"
                class="df-text"
              />
              %的平均分/得分率
            </div>
            <!-- <div class="list-cnt list-cnt1">

            </div> -->
          </div>
          <div class="list">
            <div class="list-title">错题定义</div>
            <div class="list-cnt">
              得分率小于等于
              <input v-model="from.scoreRate" type="text" class="df-text" /> %
            </div>
          </div>
          <div class="list">
            <div class="list-title">简单题定义</div>
            <div class="list-cnt">
              难度值大于等于
              <input
                v-model="from.simpleDefinition"
                type="text"
                placeholder="空"
                class="df-text"
              />
            </div>
          </div>
          <div class="list">
            <div class="list-title">错题难度</div>
            <div class="list-cnt">
              <el-radio v-model="from.difficultyOption" label="0"
                >全部</el-radio
              >
              <el-radio v-model="from.difficultyOption" label="1">
                难度大于或等于
              </el-radio>
              <input
                v-model="from.difficultyStart"
                type="text"
                placeholder="空"
                class="df-text"
              />
            </div>
          </div>
          <div
            v-if="$route.query.type == 0 || $route.query.type == 3"
            class="list"
          >
            <div class="list-title">错题类型</div>
            <div class="list-cnt">
              <template>
                <el-radio v-model="from.questionType" label="1">全部</el-radio>
                <el-radio v-model="from.questionType" label="2"
                  >简单题
                </el-radio>
                <el-radio v-model="from.questionType" label="3"
                  >粗心题
                </el-radio>
                <el-radio v-model="from.questionType" label="4"
                  >简单题加粗心题
                </el-radio>
                <el-radio
                  v-if="$route.query.type == 3"
                  v-model="from.questionType"
                  label="5"
                  >重点关注题
                </el-radio>
              </template>
            </div>
          </div>
          <template v-if="$route.query.type == 3">
            <div class="list">
              <div class="list-title">自定义群体设置</div>
            </div>
            <div class="list number-box">
              <div class="list-title">群体组</div>
              <div class="list-title list-title1">群体类型</div>
              <div class="list-title list-title2">群体范围</div>
            </div>
            <template v-for="(index, key) in customGroup">
              <div :key="key" class="list number-box">
                <div class="number-cnt">
                  {{ index.name }}
                </div>
                <div class="number-cnt number-cnt1">
                  <template v-if="index.type == 1"> 学生名单 </template>
                  <template v-if="index.type == 2"> 分数段 </template>
                  <template v-if="index.type == 3"> 排名段 </template>
                  <template v-if="index.type == 4"> 班级 </template>
                </div>
                <div v-if="index.type == 1" class="number-cnt number-cnt2">
                  <template v-if="index.studyType">
                    <span style="color: #2474ed">已上传学生名单</span>
                  </template>
                  <template v-else>
                    <span style="color: #fb5430">未上传学生名单</span>
                  </template>
                </div>
                <div v-else class="number-cnt number-cnt2">
                  <template v-if="index.type == 2"> 分数段 </template>
                  <template v-if="index.type == 3"> 排名段 </template>
                  <template v-if="index.type == 4"> 班级 </template>
                  <input
                    v-model="index.min"
                    type="text"
                    placeholder="空"
                    class="df-text"
                  />
                  到
                  <input
                    v-model="index.max"
                    type="text"
                    placeholder="空"
                    class="df-text"
                  />
                </div>
                <div class="number-cnt number-cnt3">
                  <i class="el-icon-setting" @click="editGroup(index)"></i>
                  <i class="el-icon-delete" @click="delGroup(key)"></i>
                </div>
              </div>
            </template>
            <div class="list">
              <div class="add-box" @click="toAddGroup">
                <i class="el-icon-plus"></i> 新增
              </div>
            </div>
          </template>

          <!-- <div
            class="list"
            v-if="$route.query.type == 1 || $route.query.type == 2"
          >
            <div class="list-title">巩固题推送</div>
            <div class="list-cnt">
              <template>
                <el-radio v-model="from.consolidationOption" label="1"
                  >全部</el-radio
                >
                <el-radio v-model="from.consolidationOption" label="2"
                  >重点关注题
                </el-radio>
              </template>
            </div>
          </div> -->
        </div>
      </div>
      <!--第二组-->
      <div class="item-title">
        <div class="left-box">
          <div class="num-list">2</div>
          <!--<el-button class="suc-btn" type="success" icon="el-icon-check cle-el" circle></el-button>-->
        </div>
        <div class="title">设置推送数量和范围</div>
      </div>
      <div class="item-cnt">
        <div class="item-left-box"></div>
        <div class="item-right-box">
          <div v-if="$route.query.type == 0" class="list number-box">
            <div class="list-title">班级组</div>
            <div class="list-title list-title1">总题量</div>
            <div class="list-title list-title2">题型</div>
          </div>
          <template v-if="$route.query.type == 0">
            <template v-for="(v, i) in classPushlist">
              <div :key="i" class="list number-box">
                <div class="number-cnt bj-number-box">
                  <div class="scol-cnt">
                    <p v-for="(m, n) in v.classList" :key="n">
                      {{ m }} 班
                      <template v-if="v.classList.length != n + 1">, </template>
                    </p>
                  </div>
                </div>
                <div class="number-cnt number-cnt1">
                  最少
                  <input
                    v-model="v.min"
                    type="text"
                    placeholder="空"
                    class="df-text"
                  />
                  ， 最多
                  <input
                    v-model="v.max"
                    type="text"
                    placeholder="空"
                    class="df-text"
                  />
                </div>
                <div class="number-cnt number-cnt2">
                  <div v-for="(m, n) in v.question" :key="n + 'subject'">
                    {{ m.name }}
                    最少
                    <input
                      v-model="m.val"
                      type="text"
                      placeholder="空"
                      class="df-text"
                    />
                    ， 最多
                    <input
                      v-model="m.max"
                      type="text"
                      placeholder="空"
                      class="df-text"
                    />。
                  </div>
                </div>
                <div class="number-cnt number-cnt3">
                  <i class="el-icon-setting" @click="editList(i)"></i>
                  <i class="el-icon-delete" @click="delClass(i)"></i>
                </div>
              </div>
            </template>
          </template>
          <div v-if="$route.query.type == 0" class="list">
            <div class="add-box" @click="toAdd">
              <i class="el-icon-plus"></i> 新增
            </div>
          </div>
          <div
            v-if="$route.query.type == 1 || $route.query.type == 2"
            class="list number-box"
          >
            <div class="list-title">同层班级</div>
            <div class="number-cnt number-cnt1">
              <el-radio v-model="from.sameLevelOption" label="1"
                >全年级</el-radio
              >
              <el-radio
                v-if="$route.query.type == 1"
                v-model="from.sameLevelOption"
                label="2"
                >自定义</el-radio
              >
            </div>
          </div>
          <template v-if="from.sameLevelOption == 2 && $route.query.type == 1">
            <div
              v-for="(v, i) in from.sameLevelClassList"
              :key="i"
              class="list number-box"
            >
              <div class="list-title">第{{ i + 1 }}层</div>
              <div class="number-cnt number-cnt1 bj-number-box">
                <div class="scol-cnt" style="width: 480px">
                  <p v-for="(m, n) in v" :key="n + '班'">
                    {{ m }} 班
                    <template v-if="v.length != n + 1">, </template>
                  </p>
                </div>
              </div>
              <div class="number-cnt number-cnt3">
                <i class="el-icon-setting" @click="editList1(i)"></i>
                <i class="el-icon-delete" @click="delClass1(i)"></i>
              </div>
            </div>
            <div class="list">
              <div class="add-box" @click="toAdd1">
                <i class="el-icon-plus"></i> 新增
              </div>
            </div>
          </template>
          <div
            v-if="
              ($route.query.type == 1 || $route.query.type == 2) &&
              from.consolidationOption == 2
            "
            class="list number-box"
          >
            <div class="list-title">重点关注</div>
            <div class="number-cnt number-cnt1">
              最多
              <input
                v-model="from.focusOnMaxAmount"
                type="text"
                placeholder="空"
                class="df-text"
              />
              个小题
            </div>
          </div>
          <div class="list number-box">
            <div class="list-title">变式题推题比例</div>
            <div class="number-cnt number-cnt1">
              <el-radio v-model="from.proportion" label="0">无</el-radio>
              <el-radio v-model="from.proportion" label="1">1:1</el-radio>
              <el-radio v-model="from.proportion" label="2">1:2</el-radio>
              <el-radio v-model="from.proportion" label="3">1:3</el-radio>
              <el-radio v-model="from.proportion" label="4">1:4</el-radio>
              <el-radio v-model="from.proportion" label="5">1:5</el-radio>
            </div>
          </div>
          <div class="list number-box">
            <div class="list-title">拓展题推题比例</div>
            <div class="number-cnt number-cnt1">
              <el-radio v-model="from.layeredExtensionQuestion" label="0"
                >无</el-radio
              >
              <el-radio v-model="from.layeredExtensionQuestion" label="1"
                >1:1</el-radio
              >
              <el-radio v-model="from.layeredExtensionQuestion" label="2"
                >1:2</el-radio
              >
              <el-radio v-model="from.layeredExtensionQuestion" label="3"
                >1:3</el-radio
              >
              <el-radio v-model="from.layeredExtensionQuestion" label="4"
                >1:4</el-radio
              >
              <el-radio v-model="from.layeredExtensionQuestion" label="5"
                >1:5</el-radio
              >
            </div>
          </div>
        </div>
      </div>
      <!--第三组-->
      <div class="item-title">
        <div class="left-box">
          <div class="num-list">3</div>
          <!--<el-button class="suc-btn" type="success" icon="el-icon-check cle-el" circle></el-button>-->
        </div>
        <div class="title">设置顺序、结构和样式</div>
      </div>
      <div class="item-cnt">
        <div class="item-left-box" style="background-color: white"></div>
        <div class="item-right-box">
          <div class="list number-box">
            <div class="list-title">封面标题</div>
            <div class="number-cnt number-cnt1">
              <template>
                <el-radio
                  v-model="from.coverTitleOption"
                  label="1"
                  @change="showData"
                  >默认</el-radio
                >
                <el-radio
                  v-model="from.coverTitleOption"
                  label="2"
                  @change="showData"
                  >自定义</el-radio
                >
              </template>
              <input
                v-if="from.coverTitleOption == 2"
                v-model="from.coverTitle"
                type="text"
                class="df-text"
                style="width: 300px"
                maxlength="20"
                placeholder="长度<20个字"
                show-word-limit
              />
            </div>
          </div>

          <div class="list number-box">
            <div class="list-title">考试名称是否显示</div>
            <div class="number-cnt number-cnt1">
              <template>
                <el-radio v-model="from.isShowExamName" label="1">是</el-radio>
                <el-radio v-model="from.isShowExamName" label="2">否</el-radio>
              </template>
            </div>
          </div>

          <div class="list number-box">
            <div class="list-title">考试日期是否显示</div>
            <div class="number-cnt number-cnt1">
              <template>
                <el-radio v-model="from.isShowExamDate" label="1">是</el-radio>
                <el-radio v-model="from.isShowExamDate" label="2">否</el-radio>
                <div v-if="from.isShowExamDate == 1">
                  <el-radio
                    v-model="from.examDateOptions"
                    label="1"
                    @change="showData"
                    >默认</el-radio
                  >
                  <el-radio
                    v-model="from.examDateOptions"
                    label="2"
                    @change="showData"
                    >自定义</el-radio
                  >
                </div>
              </template>
              <input
                v-if="from.examDateOptions == 2"
                v-model="from.examDate"
                type="date"
                class="df-text"
                style="width: 300px"
                maxlength="20"
                placeholder="长度<20个字"
                show-word-limit
              />
            </div>
          </div>

          <div class="list number-box">
            <div class="list-title">原题是否显示</div>
            <div class="number-cnt number-cnt1">
              <template>
                <el-radio v-model="from.isShowOriginal" label="1">是</el-radio>
                <el-radio v-model="from.isShowOriginal" label="2">否</el-radio>
              </template>
            </div>
          </div>
          <div v-if="$route.query.type == 0" class="list number-box">
            <div class="list-title">是否显示小题分</div>
            <div class="number-cnt number-cnt1">
              <template>
                <el-radio v-model="from.smallQuestionScore" label="1"
                  >是</el-radio
                >
                <el-radio v-model="from.smallQuestionScore" label="2"
                  >否</el-radio
                >
              </template>
            </div>
          </div>

          <div class="list number-box">
            <div class="list-title">错题顺序</div>
            <div class="number-cnt number-cnt1">
              <template>
                <el-radio v-model="from.wrongPriority" label="1"
                  >题号优先
                </el-radio>
                <el-radio v-model="from.wrongPriority" label="2"
                  >简单题优先
                </el-radio>
              </template>
            </div>
          </div>
          <div
            v-if="reportType == 0 && from.wrongPriority == 1"
            class="list number-box"
          >
            <div class="list-title">项目顺序</div>
            <div class="number-cnt number-cnt1">
              <template>
                <el-radio v-model="from.OrderOfItems" label="1"
                  >按时间排序
                </el-radio>
                <el-radio v-model="from.OrderOfItems" label="2">倒序</el-radio>
              </template>
            </div>
          </div>
          <div class="list number-box">
            <div class="list-title">答案位置</div>
            <div class="number-cnt number-cnt1">
              <template>
                <el-radio v-model="from.answerLocation" label="0">无 </el-radio>
                <el-radio v-model="from.answerLocation" label="1"
                  >每题之后
                </el-radio>
                <el-radio v-model="from.answerLocation" label="2"
                  >所有题之后
                </el-radio>
                <el-radio v-model="from.answerLocation" label="3"
                  >独立文件
                </el-radio>
              </template>
            </div>
          </div>
          <div class="list number-box">
            <div class="list-title">推题顺序</div>
            <div class="number-cnt number-cnt1">
              <template>
                <el-radio v-model="from.sendPriority" label="1"
                  >按顺序
                </el-radio>
                <el-radio v-model="from.sendPriority" label="2">随机</el-radio>
                <el-radio v-model="from.sendPriority" label="3"
                  >自定义顺序
                </el-radio>
              </template>
              <input
                v-model="from.sendList"
                type="text"
                placeholder="例：123"
                class="df-text"
                style="width: 100px"
              />
            </div>
          </div>

          <div class="number-box list" style="height: 102px">
            <div class="list-title">报告结构自定义</div>
            <div class="number-cnt number-cnt1">
              <template>
                <el-checkbox-group v-model="from.reportStructure">
                  <!-- "cover", "endPaper", "backCover", "area" ,"classNecessary" ,"gradeNecessary" ,"analyse" ,"variantTraining" ,"layeredExpand" -->
                  <el-checkbox label="cover">显示封面</el-checkbox>
                  <el-checkbox label="endPaper" disabled>显示扉页</el-checkbox>
                  <el-checkbox label="backCover" disabled>显示封底</el-checkbox>
                  <el-checkbox label="area">显示签字区</el-checkbox>
                  <el-checkbox label="classNecessary"
                    >显示班级必做题</el-checkbox
                  >
                  <el-checkbox label="gradeNecessary"
                    >显示年级必做题</el-checkbox
                  >
                  <el-checkbox label="analyse">成绩分析各模块</el-checkbox>
                  <el-checkbox label="variantTraining"
                    >变式题训练模块</el-checkbox
                  >
                  <el-checkbox label="layeredExpand"
                    >拓展题训练模块</el-checkbox
                  >
                </el-checkbox-group>
              </template>
            </div>
          </div>
          <div class="list number-box" style="height: 69px">
            <div class="list-title">变式题格式自定义</div>
            <div class="number-cnt number-cnt1">
              <template>
                <el-checkbox-group v-model="from.variantTrainingList">
                  <el-checkbox label="knowledgePoint">显示考点</el-checkbox>
                  <el-checkbox label="chapter">显示教材章节</el-checkbox>
                  <el-checkbox label="wrongReason">显示错因</el-checkbox>
                  <el-checkbox label="qrCode">显示试题二维码</el-checkbox>
                  <el-checkbox label="guide">显示思路点拨</el-checkbox>
                  <el-checkbox label="answerArea">显示作答区</el-checkbox>
                  <el-checkbox label="analysis">显示变式题答案解析</el-checkbox>
                </el-checkbox-group>
              </template>
            </div>
          </div>
          <div class="list number-box" style="height: 69px">
            <div class="list-title">拓展题格式自定义</div>
            <div class="number-cnt number-cnt1">
              <template>
                <el-checkbox-group v-model="from.layeredExpandList">
                  <el-checkbox label="knowledgePoint">显示考点</el-checkbox>
                  <el-checkbox label="chapter">显示教材章节</el-checkbox>
                  <el-checkbox label="wrongReason">显示错因</el-checkbox>
                  <el-checkbox label="qrCode">显示试题二维码</el-checkbox>
                  <el-checkbox label="guide">显示思路点拨</el-checkbox>
                  <el-checkbox label="answerArea">显示作答区</el-checkbox>
                  <el-checkbox label="analysis">显示拓展题答案解析</el-checkbox>
                </el-checkbox-group>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--班级列表-->
    <el-dialog title="请选择列表信息" :visible.sync="dialogVisible" width="30%">
      <div>
        <p>
          请选择班级列表
          <el-checkbox v-model="checkedClass" @change="setAllClass"
            >全选</el-checkbox
          >
          <el-button type="text" @click="setAllClass">全选</el-button>
        </p>
        <el-checkbox-group v-model="checkList">
          <el-checkbox v-for="(v, i) in classList" :key="i" :label="v">{{
            v + "班"
          }}</el-checkbox>
        </el-checkbox-group>
        <p>请选择题目列表</p>
        <el-checkbox-group v-model="subjectSuccListData">
          <el-checkbox
            v-for="(v, i) in subjectListData"
            :key="i"
            :label="v.id"
            >{{ v.name }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="setStudent">确 定</el-button>
      </span>
    </el-dialog>
    <!--班级列表-->
    <el-dialog
      title="请选择列表信息"
      :visible.sync="dialogVisible1"
      width="30%"
    >
      <div>
        <p>
          请选择班级列表
          <el-checkbox v-model="checked" @change="setListClass"
            >全选</el-checkbox
          >
          <el-checkbox v-model="checkedTo">反选</el-checkbox>
        </p>
        <el-checkbox-group v-model="checkList">
          <el-checkbox
            v-for="(v, i) in modelClassList"
            :key="i"
            :label="v"
            :disabled="setdisabled(v)"
            >{{ v + "班" }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="setClass">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 群体报告 -->
    <el-dialog
      title="群体模板参数设置"
      :visible.sync="modelGroup"
      width="30%"
      class="model-group"
      :before-close="handleClose"
    >
      <div>
        <div class="list">
          <span class="list-name">群体名称：</span>
          <el-input
            v-model="model.name"
            placeholder="请输入群体名称"
          ></el-input>
        </div>
        <div class="list">
          <span class="list-name">群体类型：</span>
          <el-radio-group v-model="model.type">
            <el-radio :label="1">学生名单</el-radio>
            <el-radio :label="2">分数段</el-radio>
            <el-radio :label="3">排名段</el-radio>
            <el-radio :label="4">班级</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div v-if="model.type == 1" class="upload-excle">
        <el-upload
          class="upload-demo"
          drag
          action="#"
          accept=".xls,.xlsx"
          multiple
          :auto-upload="false"
          :on-change="handleChange"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div slot="tip" class="el-upload__tip">
            只能上传excle文件<em
              v-if="model.studyType"
              class="study-up"
              @click="getStudentList"
              >点击查看已上传学生列表</em
            >
          </div>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="modelGroup = false">取 消</el-button>
        <el-button type="primary" class="model-btn" @click="addmodelGroup"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="学生列表"
      :visible.sync="modelStudent"
      width="40%"
      class="model-group"
    >
      <template>
        <el-table :data="tableData.records" border style="width: 100%">
          <el-table-column
            prop="studentName"
            label="姓名"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="studentNo"
            label="学号"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="classNum"
            label="班级"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
        </el-table>
      </template>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="tableData.total"
        class="study-page"
        @size-change="sizeChange"
        @prev-click="sizeChange"
        @next-click="sizeChange"
        @current-change="sizeChange"
      >
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clearSudent">清空学生</el-button>
        <el-button
          class="model-btn"
          type="primary"
          @click="modelStudent = false"
          >关 闭</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
const classList = [];
//提交的班级组装数组
const classPushlist = [
  {
    classList: [],
    min: "3",
    max: "3",
    //题目列表 题目name 题目id 题目val
    question: [],
  },
];
import { subjectOptions } from "@/core/util/constdata";
import { getExamQuestionTypeByLevelAndSubject } from "@/core/api/exam/exampaper";
import { getObj } from "@/core/api/analy/analyreportsubject";
import { importStudent, getStudent, clear } from "@/core/api/analy/analyreport";
import { getAnalyTemplate } from "@/core/api/analy/analytemplate";
import { setStore } from "@/core/util/store";

export default {
  name: "EditReport",
  data() {
    return {
      allTitle: "",
      //自定义群体
      customGroup: [],
      subjectOptions: subjectOptions,
      // fileList: [],
      //自定义群体的弹出框
      modelStudent: false,
      modelGroup: false,
      modelGroupName: "",
      modelGroupRadio: 1,
      modelEdit: false,
      tableData: {},
      checkedTo: false,
      model: {
        name: "",
        type: 1,
        studyType: false,
        max: 0,
        min: 0,
        id: "",
      },
      menuList: [
        {
          name: "学生报告",
          type: 0,
          isClick: true,
        },
        {
          name: "群体报告",
          type: 3,
          isClick: false,
        },
        {
          name: "班级报告",
          type: 1,
          isClick: false,
        },
        {
          name: "年级报告",
          type: 2,
          isClick: false,
        },
      ],
      checked: false,
      checkedClass: false,
      titleList: ["推荐", "完整", "简约"],
      reportType: "",
      // 题类型的列表
      subjectSuccListData: [],
      subjectListData: [],
      title: "推荐",
      intro: "更聪明地学习、节省时间和创建更出色的学习集",
      dialogVisible: false,
      dialogVisible1: false,
      modelClassList: [],
      subjectmodel: false,
      classList: classList,
      modelIndex: -1,
      checkList: [],
      classPushlist: classPushlist,
      checkListTrue: [],

      from: {
        // 统计
        scoreRateBeforeValue: 27,
        scoreRateEndValue: 27,
        // 巩固题推送
        consolidationOption: "1",
        // 同层班级
        sameLevelOption: "1",
        sameLevelClassList: [],
        //最多多少个小题
        focusOnMaxAmount: 1,
        //错题定义
        scoreRate: 70,
        // 简单题定义
        simpleDefinition: 0.7,
        //错题难度
        // 难度值大于等于
        difficultyOption: "0",
        difficultyStart: "",
        // 错题类型
        questionType: "1",
        // 自定义顺序
        sendList: "",
        // 推题顺序
        sendPriority: "1",
        // 错题顺序
        wrongPriority: "1",
        // 答案位置
        answerLocation: "1",
        //封面标题
        coverTitleOption: "1",
        coverTitle: "",
        // 原题是否显示
        isShowOriginal: "2",
        //考试名称
        isShowExamName: "2",
        //考试日期
        isShowExamDate: "1",
        examDateOptions: "1",
        examDate: "",
        // 项目顺序 reportType为1不解析
        OrderOfItems: "1",
        // 报告结构
        reportStructure: [
          "cover",
          "area",
          "classNecessary",
          "gradeNecessary",
          "analyse",
          "variantTraining",
          "layeredExpand",
          //  作答区 签字区 发布答案时间 试题二维码
        ],
        // 变式题格式自定义
        variantTrainingList: [
          "knowledgePoint",
          "chapter",
          "wrongReason",
          "qrCode",
          "guide",
          "answerArea",
          "analysis",
        ],
        // 拓展题格式自定义
        layeredExpandList: [
          "knowledgePoint",
          "chapter",
          "wrongReason",
          "qrCode",
          "guide",
          "answerArea",
          "analysis",
        ],
        //推题比例
        proportion: "1",
        smallQuestionScore: "1",
        // 拓展题推题比例
        layeredExtensionQuestion: "0",
      },
    };
  },
  watch: {
    // checkList(){
    //   if(this.modelClassList=this.checkList){
    //     this.checkedClass=true
    //   }else{
    //     this.checkedClass=false
    //   }
    // },
    classList() {
      // console.log(this.classList);
      this.classPushlist.map((index, arr) => {
        index.classList.map((i, a) => {
          if (this.classList.indexOf(i) == -1) {
            index.classList.splice(a, 1);
          }
        });
        if (index.classList.length == 0) {
          this.classPushlist.splice(arr, 1);
        }
      });
      // this.classPushlist.forEach((arr,index)=>{
      //   if(arr.classList.length==0){
      //     index.classList.splice(a, 1);
      //   }
      // })
      if (
        this.classList.length > 0 &&
        this.from.sameLevelClassList.length > 0
      ) {
        this.from.sameLevelClassList.map((index, arr) => {
          index.map((i, a) => {
            // console.log(this.classList.indexOf(i) == -1);
            if (this.classList.indexOf(i) == -1) {
              index.splice(a, 1);
            }
          });
          if (index.length == 0) {
            this.from.sameLevelClassList.splice(arr, 1);
          }
        });
      }
    },
    classPushlist: {
      deep: true,
      handler: function () {
        // console.log(this.classPushlist);
        if (this.classList.length > 0) {
          this.classPushlist.map((index, arr) => {
            index.classList.map((i, a) => {
              if (this.classList.indexOf(i) == -1) {
                index.classList.splice(a, 1);
              }
            });
            if (index.classList.length == 0) {
              this.classPushlist.splice(arr, 1);
            }
          });
        }
      },
    },
    checkedTo() {
      let arr = [];
      this.modelClassList.forEach((index) => {
        if (this.checkList.indexOf(index) == -1) {
          arr.push(index);
        }
      });
      this.checkList = arr;
    },
    checkList() {
      // console.log("1111111111");
      if (this.classList.length == this.checkList.length) {
        this.checkedClass = true;
      } else {
        this.checkedClass = false;
      }
      if (this.modelClassList.length == this.checkList.length) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    },
    from: {
      deep: true,
      handler: function () {
        // console.log(this.classPushlist);
        // console.log(this.classList);
        if (this.classList.length > 0) {
          this.from.sameLevelClassList.map((index, arr) => {
            index.map((i, a) => {
              // console.log(this.classList.indexOf(i) == -1);
              if (this.classList.indexOf(i) == -1) {
                index.splice(a, 1);
              }
            });
            if (index.length == 0) {
              this.from.sameLevelClassList.splice(arr, 1);
            }
          });
        }
      },
    },
  },
  created() {
    // console.log("重新加载了");
    if (this.$route.query.reportIdNum) {
      this.getObjList();
      this.classOption("1");
    } else {
      this.classOption();
      if (this.$route.query.type == 0) {
        this.from.reportStructure.push("作答区");
        this.from.reportStructure.push("签字区");
      }
    }
  },
  methods: {
    showData() {
      // let _a = this.form.coverTitleOption;
      // console.log(typeof _a);
      // console.log(_a);
    },
    setListClass() {
      if (this.modelClassList.length > this.checkList.length) {
        this.checkList = this.modelClassList;
      } else {
        this.checkList = [];
      }
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },
    clearSudent() {
      let data = {
        templateId: this.templateId,
        groupId: this.model.id,
      };
      this.$confirm("清除学生不可恢复, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          clear(data).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.modelStudent = false;
            this.model.studyType = false;
            this.customGroup.forEach((index, arr) => {
              if (this.model.id == index.id) {
                this.customGroup[arr].studyType = false;
              }
            });
          });
        })
        .catch(() => {});
    },
    sizeChange(e) {
      // console.log(e);
      let data = {
        templateId: this.templateId,
        groupId: this.model.id,
        current: e,
        size: 10,
      };
      // console.log(data);
      getStudent(data).then((res) => {
        // console.log(res);
        this.tableData = res.data.data;
      });
    },
    getStudentList() {
      this.modelStudent = true;
      let data = {
        templateId: this.templateId,
        groupId: this.model.id,
        current: 1,
        size: 10,
      };
      // console.log(data);
      getStudent(data).then((res) => {
        // console.log(res);
        this.tableData = res.data.data;
      });
    },
    handleChange(e) {
      // console.log(e);
      let formData = new FormData();
      formData.append("file", e.raw);
      formData.append("templateId", this.templateId);
      formData.append("groupId", this.model.id);
      formData.append("reportSubjectId", this.$route.query.id);
      // .reportSubjectId = this.$route.query.id
      importStudent(formData).then(() => {
        this.model.studyType = true;
        // if (this.model.name == "") {
        //   this.model.name = "默认学生列表";
        // }

        // this.modelEdit = true;
        // if (
        //   this.customGroup.filter(item => item.id == this.model.id).length > 0
        // ) {
        //   this.customGroup.forEach((index, arr) => {
        //     if (index.id == this.model.id) {
        //       this.customGroup[arr] = JSON.parse(JSON.stringify(this.model));
        //     }
        //   });
        // } else {
        //   this.customGroup.push(JSON.parse(JSON.stringify(this.model)));
        // }
      });
    },
    //  toAddGroup editGroup delGroup
    delGroup(index) {
      if (this.customGroup.length == 1) {
        this.$message({
          message: "至少选择存在一条",
          type: "warning",
        });
        return;
      }
      this.$confirm("删除后不可恢复, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.customGroup.splice(index, 1);
        })
        .catch(() => {});
    },
    addmodelGroup() {
      if (this.model.name == "") {
        this.$message({
          message: "请输入群体组名称",
          type: "warning",
        });
        return;
      }
      // 编辑
      this.model.reportSubjectId = this.$route.query.id;
      // console.log(this.model);
      // console.log(this.customGroup);
      if (this.modelEdit) {
        this.customGroup.forEach((i, a) => {
          if (i.id == this.model.id) {
            this.customGroup[a] = JSON.parse(JSON.stringify(this.model));
          }
        });
        this.modelGroup = false;
      } else {
        this.customGroup.push(JSON.parse(JSON.stringify(this.model)));
        this.modelGroup = false;
      }
    },
    toAddGroup() {
      Object.assign(this.$data.model, this.$options.data().model);
      this.model.id = new Date().getTime();
      // this.model.name = "";
      this.modelEdit = false;
      this.modelGroup = true;
    },
    editGroup(val) {
      this.modelEdit = true;
      this.modelGroup = true;
      this.model = JSON.parse(JSON.stringify(val));
      // console.log(this.model);
    },
    setdisabled(val) {
      // console.log(val);
      // console.log(this.from.sameLevelClassList);
      let type = false;
      this.from.sameLevelClassList.forEach((i) => {
        i.forEach((m) => {
          if (m == val) {
            type = true;
          }
        });
      });
      // console.log()
      //创建一个临时公有变量
      // let arr = JSON.parse(JSON.stringify(this.checkList));
      this.checkListTrue.forEach((i) => {
        if (i == val) {
          type = false;
        }
      });
      return type;
    },
    getObjList() {
      let data = {
        reportSubjectId: this.$route.query.id,
      };
      getAnalyTemplate(this.$route.query.reportIdNum, data).then((res) => {
        // console.log(res);
        this.templateId = res.data.data.id;
        this.title = res.data.data.name;
        this.intro = res.data.data.description;
        // this.from = JSON.parse(res.data.data.setting);
        this.from = eval("(" + res.data.data.setting + ")");

        // 新加参数
        if (!this.from.variantTrainingList) {
          this.from.variantTrainingList = [
            "knowledgePoint",
            "chapter",
            "wrongReason",
            "qrCode",
            "guide",
            "answerArea",
            "analysis",
          ];
        }
        if (!this.from.layeredExpandList) {
          this.from.layeredExpandList = [
            "knowledgePoint",
            "chapter",
            "wrongReason",
            "qrCode",
            "guide",
            "answerArea",
            "analysis",
          ];
        }

        this.classPushlist = this.from.classPushlist;
        if (this.$route.query.type == 0 && !this.from.smallQuestionScore) {
          this.from.smallQuestionScore = "1";
        }
        //兼容一下以前数据
        // console.log(this.classPushlist);
        // this.classPushlist.map(item => {
        //   if (item.question && item.question.length > 0) {
        //     item.question.map(index => {
        //       if (!index.max) {
        //         index.max = "";
        //       }
        //     });
        //   }
        // });
        // this.classPushlist = Object.assign({}, this.classPushlist);
        // question
        if (!("coverTitleOption" in this.from)) {
          let _cover = { coverTitleOption: "1" };
          this.from = Object.assign(_cover, this.from);
        }
        if (this.from.customGroup) {
          this.customGroup = this.from.customGroup;
        }

        if (
          this.from.scoreRateBeforeValue == undefined &&
          (this.$route.query.type == 1 || this.$route.query.type == 2)
        ) {
          this.from.scoreRateBeforeValue = 27;
          this.from.scoreRateEndValue = 27;
          this.from.consolidationOption = "1";
          this.from.sameLevelOption = "1";
          this.from.sameLevelClassList = [];
          this.from.focusOnMaxAmount = 1;
        }
        if (!this.from.layeredExtensionQuestion) {
          this.from.layeredExtensionQuestion = "0";
        }
        this.from = JSON.parse(JSON.stringify(this.from));
        // this.$nextTick();=
        // console.log(this.from);
      });
    },
    setAllClass() {
      // console.log(this.checked);
      if (this.checked) {
        this.checkList = this.classList;
      } else {
        this.checkList = [];
      }
    },
    editList1(val) {
      this.modelIndex = val;
      this.dialogVisible1 = true;
      this.checkList = this.from.sameLevelClassList[val];
      this.checkListTrue = JSON.parse(JSON.stringify(this.checkList));
      this.modelClassList = [];
      let arr = [];
      this.from.sameLevelClassList.forEach((index) => {
        index.forEach((item) => {
          arr.push(item);
        });
      });
      this.classList.forEach((item) => {
        if (arr.indexOf(item) == -1) {
          this.modelClassList.push(item);
        }
      });

      this.modelClassList = [
        ...new Set(this.modelClassList.concat(this.checkList)),
      ];
      // console.log(this.modelClassList);
      // console.log(this.checkListTrue);
    },
    editList(val) {
      this.modelIndex = val;
      this.dialogVisible = true;
      //console.log(this.classPushlist[val]);
      this.checkList = this.classPushlist[val].classList;
      let arr = [];
      this.classPushlist[val].question.forEach((i) => {
        arr.push(i.id);
      });
      //console.log(arr);
      this.subjectSuccListData = arr;
    },
    linkTo() {
      let query = this.$route.query;
      delete query.reportIdNum;
      this.$router.push({
        name: "manageList",
        query: query,
        // query: this.$route.query
      });
    },
    classOption(val) {
      //判断一下是什么模板
      let typeModel = this.$route.query.typeModel;
      // if(typeModel)
      this.titleList.forEach((i, a) => {
        if (typeModel == a) {
          this.title = i;
        }
      });
      getObj(this.$route.query.id).then((res) => {
        this.classList = res.data.data.classNums.split(",");
        this.reportType = res.data.data.reportType;
        this.allTitle = res.data.data.reportName;
        subjectOptions.forEach((i) => {
          if (i.value == res.data.data.subjectId) {
            this.allTitle = this.allTitle + ` (${i.label})`;
          }
        });
        // subjectOptions

        // this.
        if (
          !this.from.sameLevelClassList ||
          this.from.sameLevelClassList.length == 0
        ) {
          this.from.sameLevelClassList = [];
          this.from.sameLevelClassList.push(this.classList);
        }
        //this.getSubjectList();
        this.getSubjectList(res.data.data.levelId, res.data.data.subjectId);

        if (!val) {
          // console.log(this.classPushlist);
          this.classPushlist[0].classList = this.classList;
          this.classPushlist[0].question = [];
          // this.from.sameLevelClassList.push(this.classList);
          // console.log(this.from.sameLevelClassList);
        }
      });
    },
    //getSubjectList() {},
    getSubjectList(levelId, subjectId) {
      let data = new URLSearchParams();
      data.append("level", levelId);
      data.append("subjectId", subjectId);

      getExamQuestionTypeByLevelAndSubject(data).then((res) => {
        this.subjectListData = res.data.data.resQuestionTypes;
      });
    },
    toAdd() {
      this.modelIndex = -1;
      this.dialogVisible = true;
      this.checkList = [];
    },
    toAdd1() {
      this.dialogVisible1 = true;
      this.checkList = [];
      this.checkListTrue = [];
      this.modelClassList = [];
      let arr = [];
      this.from.sameLevelClassList.forEach((index) => {
        index.forEach((item) => {
          arr.push(item);
        });
      });
      this.classList.forEach((item) => {
        if (arr.indexOf(item) == -1) {
          this.modelClassList.push(item);
        }
      });

      this.modelClassList = [
        ...new Set(this.modelClassList.concat(this.checkList)),
      ];
      // console.log(this.modelClassList);
    },
    delClass(i) {
      // //console.log(id);
      if (this.classPushlist.length == 1) {
        this.$message({
          message: "至少选择存在一条",
          type: "warning",
        });
        return;
      }
      this.classPushlist.splice(i, 1);
    },
    delClass1(i) {
      if (this.from.sameLevelClassList.length == 1) {
        this.$message({
          message: "至少选择存在一条",
          type: "warning",
        });
        return;
      }
      this.from.sameLevelClassList.splice(i, 1);
    },
    setClass() {
      this.checkList.sort();
      if (this.checkList.length == 0) {
        this.$message({
          message: "至少选择一个班级",
          type: "warning",
        });
        return;
      }
      if (this.modelIndex == -1) {
        this.from.sameLevelClassList.push(this.checkList);
      } else {
        this.from.sameLevelClassList[this.modelIndex] = this.checkList;
        this.modelIndex = -1;
      }
      this.dialogVisible1 = false;
      // console.log(this.from.sameLevelClassList);
    },
    setStudent() {
      let sbjArr = [];
      this.subjectSuccListData.sort();
      this.checkList.sort();
      this.subjectSuccListData.forEach((i) => {
        this.subjectListData.forEach((index) => {
          if (i == index.id) {
            // console.log(index);
            sbjArr.push({
              name: index.name,
              val: "",
              max: "",
              id: index.id,
            });
          }
        });
      });
      if (this.checkList.length == 0) {
        this.$message({
          message: "至少选择一个班级",
          type: "warning",
        });
        return;
      }
      if (this.modelIndex == -1) {
        this.classPushlist.push({
          classList: this.checkList,
          min: "",
          max: "",
          question: sbjArr,
        });
      } else {
        this.classPushlist[this.modelIndex].classList = this.checkList;
        // 添加新增的對象
        sbjArr.map((index) => {
          this.classPushlist[this.modelIndex].question.forEach((i) => {
            if (i.id == index.id) {
              index.val = i.val;
              index.max = i.max;
            }
          });
        });
        this.classPushlist[this.modelIndex].question = sbjArr;
        this.modelIndex = -1;
      }
      this.subjectSuccListData = [];
      this.dialogVisible = false;
    },
    subFrom() {
      // // console.log(this.from);
      if (this.title == "") {
        this.$message({
          message: "请输入标题",
          type: "warning",
        });
        return;
      }
      if (this.intro == "") {
        this.$message({
          message: "请输入简介",
          type: "warning",
        });
        return;
      }
      if (this.from.scoreRate == "") {
        this.$message({
          message: "请输入错题定义",
          type: "warning",
        });
        return;
      }
      if (this.from.simpleDefinition == "") {
        this.$message({
          message: "请输入简单题定义",
          type: "warning",
        });
        return;
      }
      if (this.customGroup == []) {
        this.$message({
          message: "至少新增一个群体组",
          type: "warning",
        });
        return;
      }
      let group = false;
      let groupNum = false;
      this.customGroup.forEach((i) => {
        if (i.type == 1 && i.studyType == false) {
          group = true;
        }
        if (i.type != 1) {
          if (Number(i.min) >= Number(i.max)) {
            groupNum = true;
          }
          // groupNum
        }
      });
      if (group) {
        this.$message({
          message: "群体组为学生必须上传学生列表",
          type: "warning",
        });
        return;
      }
      if (groupNum) {
        this.$message({
          message: "群体组内参数设置有误",
          type: "warning",
        });
        return;
      }
      if (
        this.from.difficultyOption == "1" &&
        this.from.difficultyStart == ""
      ) {
        this.$message({
          message: "请输入错题难度",
          type: "warning",
        });
        return;
      }
      // 班级判断
      let isType = false;
      let isNumType = false;
      let isNumAll = false;
      let numberType = false;
      this.classPushlist.forEach((i) => {
        if (i.min == "" || i.max == "") {
          isType = true;
        }
        if (Number(i.min) > Number(i.max)) {
          isNumType = true;
        }
        let max = i.max;
        let numberAll = 0;

        i.question.forEach((index) => {
          numberAll = Number(index.val) + numberAll;
          if (index.max != "") {
            if (Number(index.max) < Number(index.val)) {
              numberType = true;
            }
          }
        });
        if (numberAll > max) {
          isNumAll = true;
        }
      });
      if (isType) {
        this.$message({
          message: "请设置推送数量和范围",
          type: "warning",
        });
        return;
      }
      if (isNumType) {
        this.$message({
          message: "推题最小值不能大于最大值",
          type: "warning",
        });
        return;
      }
      if (numberType) {
        this.$message({
          message: "题型最小值不能大于最大值",
          type: "warning",
        });
        return;
      }
      if (isNumAll) {
        this.$message({
          message: "题型最小数量不能大于总题量最大值",
          type: "warning",
        });
        return;
      }

      if (this.from.sendPriority == 3 && this.from.sendList == "") {
        this.$message({
          message: "请输入自定义范围",
          type: "warning",
        });
        return;
      }
      if (this.from.reportStructure.length == 0) {
        this.$message({
          message: "报告结构至少选择一项",
          type: "warning",
        });
        return;
      }
      //所有判断条件过了，就开始组装数据;
      // console.log(this.from);
      // this.from.title = this.title;
      // this.from.intro = this.intro;
      setStore({
        name: "title",
        content: this.title,
      });
      setStore({
        name: "intro",
        content: this.intro,
      });
      this.from.classPushlist = this.classPushlist;
      this.from.customGroup = this.customGroup;
      setStore({
        name: "reportObj",
        content: this.from,
      });
      // console.log(this.from);
      this.$router.push({
        name: "reportStructure",
        query: this.$route.query,
      });
    },
  },
};
</script>
<style lang="scss">
.editReport {
  .el-upload-list.el-upload-list--text {
    display: none;
  }
  .model-btn {
    background-color: #2474ed;
    border-color: #2474ed;
  }
  .model-group {
    .el-dialog__body {
      padding: 20px;
      .el-radio-group {
        margin-top: 10px;
      }
      .upload-excle {
        width: 360px;
        margin: 16px auto 0;
      }
      .list {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e0e0e0;
        padding: 6px 0;
        &:first-child {
          border-top: 1px solid #e0e0e0;
        }
        .list-name {
          white-space: nowrap;
        }
      }
    }
  }
  .is-circle {
    padding: 6px;
  }

  .cle-el {
    color: white;
  }

  .el-radio__input.is-checked .el-radio__inner {
    border-color: #2474ed;
    background: #2474ed;
  }

  .el-radio__input.is-checked + .el-radio__label {
    color: #2474ed;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    border-color: #2474ed;
    background: #2474ed;
  }

  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #2474ed;
  }
}
</style>
<style scoped lang="scss">
.editReport {
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 1280px;
  margin: 30px auto;

  .study-page {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  .study-up {
    color: #2474ed;
    cursor: pointer;
    margin-left: 12px;
  }
  .content-box {
    padding: 50px 162px;

    .cnt-title {
      .el-title {
        margin-bottom: 25px;
        width: 600px;
      }
    }

    .item-cnt {
      display: flex;

      .item-left-box {
        /*margin-right: px;*/
        width: 2px;
        margin: 8px 40px 8px 14px;
        background-color: rgba(0, 0, 0, 0.1);
      }

      .item-right-box {
        width: calc(100% - 55px);
        margin-top: 32px;
        margin-bottom: 62px;

        .list:last-child {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }

        .list {
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          display: flex;
          padding: 10px;
          position: relative;
          width: 100%;

          .add-box {
            display: inline-block;
            cursor: pointer;
            color: rgba(0, 0, 0, 0.5);

            .el-icon-plus {
              color: rgba(0, 0, 0, 0.5);
            }
          }

          .list-title {
            color: rgba(0, 0, 0, 0.5);
          }
          .list-cnt1.list-cnt {
            left: 400px;
          }
          .list-cnt {
            position: absolute;
            left: 208px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.85);

            .df-text {
              width: 30px;
              border-bottom: 1px solid #999999;
              text-align: center;
            }

            input:focus {
              outline: none;
            }
          }
        }
        .bj-number-box {
          .scol-cnt {
            z-index: 10;
            overflow-x: scroll;
            width: 173px;
            &::-webkit-scrollbar {
              width: 3px;
              height: 3px;
            }

            ///*<!--修改 滑块 -->*/
            &::-webkit-scrollbar-thumb {
              background-color: rgba(0, 0, 0, 0.25);
            }
            p {
              white-space: nowrap;
              margin-bottom: 0;
            }
          }
        }

        .number-box {
          /*display: flex;*/
          /*justify-content: space-between;*/
          position: relative;
          min-height: 40px;

          .list-title1 {
            position: absolute;
            left: 208px;
          }

          .list-title2 {
            position: absolute;
            left: 400px;
          }

          .df-text {
            width: 30px;
            border-bottom: 1px solid #999999;
            text-align: center;
          }

          input:focus {
            outline: none;
          }

          .number-cnt {
            position: absolute;
            left: 10px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.85);
            display: flex;
            align-items: center;
          }

          .number-cnt1 {
            left: 208px;
          }

          .scol-cnt {
            display: flex;
          }

          .number-cnt2 {
            left: 400px;
            display: flex;
            overflow-x: scroll;
            width: 360px;
            div {
              white-space: nowrap;
            }
            &::-webkit-scrollbar {
              width: 3px;
              height: 3px;
            }

            ///*<!--修改 滑块 -->*/
            &::-webkit-scrollbar-thumb {
              background-color: #2474ed;
            }
          }

          .number-cnt3 {
            left: 800px;
            i {
              cursor: pointer;
            }

            .el-icon-delete {
              cursor: pointer;
              margin-left: 14px;
            }
          }
        }
      }
    }

    .item-title {
      display: flex;
      align-items: center;

      .title {
        font-size: 20px;
        font-weight: bold;
      }

      .left-box {
        margin-right: 40px;

        .suc-btn {
          background-color: #2474ed;
          border-color: #2474ed;
        }

        .num-list {
          padding: 5px 12px;
          font-size: 14px;
          background-color: rgba(0, 0, 0, 0.05);
          border-radius: 50%;
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  .title-box {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    padding: 16px 64px;
    align-items: center;

    .title {
      font-size: 24px;
      font-weight: bold;
    }

    .btn-box {
      .cli-btn {
        background-color: #2474ed;
        border-color: #2474ed;
      }

      .btn-text {
        color: #2474ed;
      }

      .el-icon--right {
        color: white;
      }
    }
  }
}
</style>
